import "../main.css";
import TeamMember from "./TeamMember";
import ProfileImage from "./ProfileImage"; 
import brown from "../assets/images/Crystal Brown square.jpg"
import deangelo from "../assets/images/Joe Deangelo.png"
import schwartz from "../assets/images/Steve Schwartz.jpeg"
import freddolino from "../assets/images/Lydia Freddolino, Ph.D.jpg"
import walter from "../assets/images/Nils G. Walter, Ph.D..jpg"
import grenawitzke from "../assets/images/William Grenawitzke.png"
import wesselhoeft from "../assets/images/Alex Wesselhoeft, Ph.D..png"
import mit from "../assets/images/2560px-MIT_logo.png"
import ku from "../assets/images/KetteringUniversity-logo-350x350.png"
import msu from "../assets/images/MSU logo.png"
import um from "../assets/images/U-M_Logo-Hex.png"
import rp from "../assets/images/revealpharma_logo.jpg"
import bc from "../assets/images/banc-of-california-logo-square.png"
import yale from "../assets/images/yale-university-logo.jpg"

function Team() {
  return (
    <section id="team">
      <div className="flex-col center">
        <div className="team-header">
          <h1>Our Leadership</h1>
        </div>
        <div className="grid-team flex-col center">
          <div className="team-div">
            <TeamMember 
              name={"Crystal Brown"}
              title={"Chief Executive Officer"}
              image={brown}
              linkedIn={"https://www.linkedin.com/in/crystal-brown-mba-489285210/"}
              logos={[msu, um]}
              experience={["Former CEO, Great Lakes Biologics", "Ocuphire Pharma", "Gemphire Therapeutics"]}
              imageStyle={"profile-image"}
            />
          </div>
          <div className="team-div">
            <TeamMember 
              name={"Joe Deangelo"}
              title={"Chief Scientific Officer"}
              image={deangelo}
              linkedIn={"https://www.linkedin.com/in/joedeangelo1/"}
              logos={[mit]}
              experience={["Former CEO, Neochromosome", "Former CSO, Apex Bioscience", "Great Lakes Biologics"]}
              imageStyle={"profile-image"}
            />
          </div>
          <div className="team-div">
            <TeamMember 
              name={"Steve Schwartz"}
              title={"Chief Technology Officer"}
              image={schwartz}
              linkedIn={"https://www.linkedin.com/in/jangosteve/"}
              logos={[ku]}
              experience={["Former CTO, Genomenon", "Former CTO, CarCode"]}
              imageStyle={"profile-image"}
              />
          </div>
          <div className="team-div">
            <TeamMember 
              name={"William Grenawitzke"}
              title={"Chief Business Officer"}
              image={grenawitzke}
              linkedIn={"https://www.linkedin.com/in/william-grenawitzke/"}
              logos={[yale, um]}
              experience={["Ironwood Pharmaceuticals", "Mylan", "Merrill Lynch"]}
              imageStyle={"profile-image"}
              />
          </div>
          <div className="team-div">
            <TeamMember 
              name={"Alex Wesselhoeft"}
              title={"Principal Scientific Architect"}
              image={wesselhoeft}
              linkedIn={"https://www.linkedin.com/in/r-alexander-wesselhoeft-b5086494/"}
              logos={[mit]}
              experience={["Mass General Brigham", "Founder, Orna Therapeutics"]}
              imageStyle={"profile-image"}
              />
          </div>
        </div>
      </div>
      <div className="flex-col center">
        <div className="advisors-header">
          <h1>Our Advisors</h1>
        </div>
        <div className="grid-advisor flex-col center">
          <div className="advisor-div">
            <ProfileImage 
              name={"Lydia Freddolino"}
              image={freddolino}
              logos={[um]}
              linkedIn={"https://www.linkedin.com/in/lydia-freddolino-56511882/"}
              experience={["Associate Professor, the University of Michigan"]}
              imageStyle={"small-profile-image"}
            />
          </div>
          <div className="advisor-div">
            <ProfileImage 
              name={"Nils G. Walter"}
              image={walter}
              logos={[um]}
              linkedIn={"https://www.linkedin.com/in/nils-walter-23186b65/"}
              experience={["Francis S. Collins Collegiate Professor of Chemistry, Biophysics, and Biological Chemistry, University of Michigan"]}
              imageStyle={"small-profile-image"}
            />
          </div>
        </div>
      </div>
  </section>
  );
}

export default Team;
