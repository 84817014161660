import "../main.css";
import React, { useState } from 'react';

function Login({}) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = () => {
    if (!email || !password) {
      setErrorMessage('Email and password are required.');
    } else if (email !== 'valid_email@example.com' || password !== 'valid_password') {
      setErrorMessage('Incorrect email or password.');
    } else {
      console.log('Login successful!');
    }
  };

  return (
    <section id="login" className="flex-col center center-text background-logo bottom-right">
      <div className="login-container">
        <h1>Customer Portal</h1>
        <div className="flex-col center">
          <label htmlFor="email" className="visually-hidden">Email:</label>
          <input type="email" id="email" className="login" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>

          <label htmlFor="password" className="visually-hidden">Password:</label>
          <input type="password" id="password" className="login" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"/>

          <button className="login-btn" onClick={handleLogin}>Login</button>

          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </section>
  );
}

export default Login;
