import '../main.css';
import { NavHashLink } from 'react-router-hash-link';
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from "react-icons/fa";
import logo from "../assets/logos/CircNova Logo.png"

function Footer() {
  return (
    <footer className="grid-footer">
      <div class="footer-div1">
        <img src={logo} className="nav-logo" alt="nav-logo" />
      </div>
      <div class="footer-div2 flex-col center">
        <div className="flex-row vertical-center">
          {/* <FaFacebookSquare className="social-icon" size={40} onClick={() => window.open("https://facebook.com")}/>
          <FaInstagram className="social-icon" size={40} onClick={() => window.open("https://instagram.com")}/>
          <FaTwitterSquare className="social-icon" size={40} onClick={() => window.open("https://www.x.com")}/> */}
        </div>
        <div className="flex-row vertical-center">
          <NavHashLink smooth to="#about" className="nav-btn">Our Company</NavHashLink>
          <NavHashLink smooth to="#vision" className="nav-btn">Our Vision</NavHashLink>
          <NavHashLink smooth to="#technology" className="nav-btn">Our Technology</NavHashLink>
          <NavHashLink smooth to="#mission" className="nav-btn">Our Mission</NavHashLink>
          <NavHashLink smooth to="#team" className="nav-btn">Our Team</NavHashLink>
          <NavHashLink smooth to="#contact" className="nav-btn">Contact Us</NavHashLink>
          <NavHashLink smooth to="/login" className="nav-btn" target="_blank">Customer Portal</NavHashLink>
        </div>
        <p>© 2024 CircNova All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
