import "../main.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Team from "./Team";
import ContactUs from "./ContactUs";
import rnaImage from "../../src/assets/images/circnova-novaengine-circular-rna-predicted-structure.png"
import rnaAnimation from "../../src/assets/images/circnova-circrna-animated-small.webm"
import rnaAnimationFallback from "../../src/assets/images/circnova-circrna-animated-small.mp4"

function Home() {
  return (
    <>
      <Header/>

      {/* WHAT WE DO */}
      <section id="about" className="grid-about dot-parent">
        <div className="flex-col center background-logo top-left dot-parent">
          <span className="red-dot large"></span> 
          <div className="large-circle-cropper" id="what-we-do-image">
          </div>
        </div>
        <div className="dot-parent"> 
          <span className="gray-dot small"></span> 
          <h1 className="red">Our Company</h1>
          <p className="medium-black-text">CircNova, Inc. is a biotechnology company that leverages our unique AI NovaEngine™ to generate, analyze and identify circular RNA for therapeutic development.</p>
          <p className="medium-black-text">Our experts focus on non-coding RNA with many mechanisms of action against undruggable disease targets for major unmet clinical needs.</p>
          <p className="medium-black-text">CircNova is developing a robust IP portfolio for development of circRNA therapeutics.</p>
          <button className="learn-more-btn">Learn More</button>
        </div>
        <span className="red-dot medium"></span>
      </section>

      {/* VISION */}
      <section id="vision" className="flex-col center">
        <div className="center-text">
          <h1>Our Vision</h1>
          <p className="large-gray-text"><strong>We are driven by our passion to innovate, discover and lead the development of life-changing RNA based therapies.</strong><br/>
          We offer partners and patients new solutions to previously undruggable targets that lead to better quality of life.</p>
        </div>
      </section>

      {/* TECHNOLOGY */}
      <section id="technology" className="grid-technology">
        <div className="flex-col">
          <video class="technology-image" autoPlay loop="true" muted playsInline>
            <source src={rnaAnimation} />
            <source src={rnaAnimationFallback} />
          </video>
          <caption>World's first circRNA 3D structure modeled with deep-learning</caption>
        </div>
        <div className="technology-text dot-parent flex-col"> 
          <span className="gray-dot small"></span> 
          <h1>Our Technology</h1>
          <p className="large-gray-text"><strong>Generative Artificial Intelligence has arrived</strong> to deliver novel applications in drug discovery, gene therapy, and biotechnology.</p>
          <p className="large-gray-text">AI-guided RNA-folding and active structure prediction coupled with high-throughput RNA synthesis enables revolutionary efficiencies in effective development of therapeutic candidates.</p>
          <p className="large-black-text">Our AI NovaEngine™ is the first in the world to predict circular RNA structures using deep-learning, allowing unprecedented capability in therapeutic design for "undruggable" diseases, such as Triple-negative Breast Cancer.</p>
        </div>
        <span className="red-dot medium"></span>
      </section>

      {/* MISSION */}
      <div className="background-logo bottom dot-parent">
        <section id="mission" className="grid-mission background-logo top-right">
          <div className="mission-div1 dot-parent"> 
            <span className="black-dot large"></span>
            <h1 className="red">Our Mission</h1>
            <p className="large-black-text">We realize our aspiration through investment in continuous AI learning activities, novel data generation and NovaEngine™ pipeline growth.</p>
          </div>
          <div className="mission-div2 dot-parent">
            <span className="red-dot medium-mission"></span>
            <p className="large-gray-text"><strong>Our value</strong> is bolstered by innovative engagement and cutting-edge discoveries through key partnerships and collaborations with top research institutions.</p>
          </div>
          <div className="mission-div3 dot-parent">
            <span className="red-dot large-mission"></span>
            <div className="large-circle-cropper" id="our-mission-image">
            </div>
          </div>
          <div className="mission-div4 dot-parent">
            <span className="black-dot medium"></span>
            <p className="large-gray-text"><strong>Our culture</strong> is built upon the collective strength of our diversity, expertise and receptivity as leaders.</p>
          </div>
          <div className="mission-div5 dot-parent">
            <span className="gray-dot medium"></span>
            <p className="large-gray-text"><strong>Our future</strong> is shaped by a shared experience of inclusivity and the superior performance of our industry-leading team and technology.</p>
          </div>
        </section>
        <span className="red-dot small-mission"></span>
      </div>

      {/* TEAM */}
      <Team/>
      
      {/* CONTACT US */}
      <ContactUs/> 

      <Footer/>
    </>
  );
}

export default Home;
