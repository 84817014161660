import '../main.css';
import { NavHashLink } from 'react-router-hash-link';
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from "react-icons/fa";
import logo from "../assets/logos/CircNova Logo.png"
import novaengine from "../assets/logos/Novaengine logo.svg"
import splashvideo from "../assets/images/CircNova-RNA.webm"
import splashvideofallback from "../assets/images/CircNova-RNA.mp4"

function Header() {
  return (
    <header className="dot-parent">
      <video class="header-video" autoPlay loop="true" muted playsInline>
        <source src={splashvideo} />
        <source src={splashvideofallback} />
      </video>
      <nav className="navbar-container" role="navigation">
        <img src={logo} className="nav-logo" alt="nav-logo" />
        <div className="nav-bar">
          <div className="flex-row end">
            {/* <FaFacebookSquare className="social-icon" size={20} onClick={() => window.open("https://facebook.com")}/>
            <FaInstagram className="social-icon" size={20} onClick={() => window.open("https://instagram.com")}/>
            <FaTwitterSquare className="social-icon" size={20} onClick={() => window.open("https://www.x.com")}/> */}
          </div>
          <div className="flex-row vertical-center">
            <NavHashLink smooth to="#about" className="nav-btn">Our Company</NavHashLink>
            <NavHashLink smooth to="#vision" className="nav-btn">Our Vision</NavHashLink>
            <NavHashLink smooth to="#technology" className="nav-btn">Our Technology</NavHashLink>
            <NavHashLink smooth to="#mission" className="nav-btn">Our Mission</NavHashLink>
            <NavHashLink smooth to="#team" className="nav-btn">Our Team</NavHashLink>
            <NavHashLink smooth to="#contact" className="nav-btn">Contact Us</NavHashLink>
            <NavHashLink smooth to="/login" className="nav-btn" target="_blank">Customer Portal</NavHashLink>
          </div>
        </div>

        <div id="menu-toggle">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <NavHashLink smooth to="#about"><li>Our Company</li></NavHashLink>
            <NavHashLink smooth to="#vision"><li>Our Vision</li></NavHashLink>
            <NavHashLink smooth to="#technology"><li>Our Technology</li></NavHashLink>
            <NavHashLink smooth to="#mission"><li>Our Mission</li></NavHashLink>
            <NavHashLink smooth to="#team"><li>Our Team</li></NavHashLink>
            <NavHashLink smooth to="#contact"><li>Contact Us</li></NavHashLink>
            <NavHashLink smooth to="/login" target="_blank"><li>Customer Portal</li></NavHashLink>
          </ul>
        </div>
      </nav>
      <div className="grid-header">
        <div className="flex-col center">
          <img src={novaengine} className="novaengine-logo" alt="novaengine-logo" />
          <div className="flex-col center header-content">
            <h1 className="header-title red">Programmable RNA Therapeutics</h1>
            <NavHashLink smooth to="#contact" className="info-btn">Contact Us</NavHashLink>
          </div>
        </div>
        <div className="dot-parent">
          <span className="red-dot small"></span> 
          <span className="lg-white-dot"></span> 
        </div>
      </div>
      <span className="black-dot small"></span> 
    </header>
  );
}

export default Header;
