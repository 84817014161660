import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha"
import "../main.css";

function ContactUs() {

  const [showForm, setShowForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const captchaRef = useRef(null);

  const handleSendMessageClick = () => {
    setShowForm(true);
  };

  const handleSubmit = async (e) =>{
    e.preventDefault();
    const data = new FormData(e.target);
    let object = {};
    data.forEach((value, key) => {
      object[key] ||= value;
    });
    const body = JSON.stringify(object);

    try {
      const requestData = {
        method: 'POST',
        body: body
      };

      const response = await fetch(e.target.action, requestData);
      const responseJSON = await response.json();

      captchaRef.current.reset();
      setShowForm(false);
      setSubmitted(true);

    } catch (error) {
      console.log(error);
    }
  }

  let recaptchaValue = null;
  const onCaptchaChange = (value) =>{
    //console.log("Captcha value:", value);
    recaptchaValue = value;
  }

  return (
    <div className="background-logo bottom-right">
      <section id="contact" className="center-text">
        <h1 className="red">Contact Us</h1>
        <p className="large-black-text">Please send us a message to connect!</p>

        {!showForm && !submitted && (
          <button className="send-message-btn" onClick={handleSendMessageClick}>
            Send Message
          </button>
        )}

        { submitted && (
          <span className="notification">Thanks for sending us a message! We will respond shortly.</span>
        )}

        {showForm && (
          <form className="flex-col center" action="https://jizcptfhqzgi2cas35yb2phezm0taawb.lambda-url.us-east-1.on.aws/" method="POST" onSubmit={handleSubmit}>
            <input type="hidden" id="page_url" name="page_url" value={ window.location.pathname } />
            <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" value={ recaptchaValue } />

            <label htmlFor="name" className="visually-hidden">Name:</label>
            <input type="text" id="name" name="full_name" placeholder="Name" />

            <label htmlFor="email" className="visually-hidden">Email:</label>
            <input type="email" id="email" name="email" placeholder="Email" />

            <label htmlFor="message" className="visually-hidden">Message:</label>
            <textarea id="message" name="message" placeholder="Message" rows="6" />
            <ReCAPTCHA sitekey="6LcscEYpAAAAAPFUiGwBKu5g-ZSNK9iHmbYkzl85" ref={captchaRef} onChange={onCaptchaChange} />

            <button className="submit-btn" type="submit">Submit</button>
          </form>
        )}

        <p className="red">CircNova, Inc.</p>
        <p>2050 15th Street, Detroit, Michigan 48208, United States<br/>
          1 Broadway, Cambridge, Massachusetts, United States info@circnova.com</p>
        <p className="red">info@circnova.com</p>
        <p>(734) 890-3603</p>
      </section>
    </div>
  );
}

export default ContactUs;
